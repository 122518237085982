import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Typography,
  Box,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Button
} from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useNavigate } from 'react-router-dom';
import { getNotifications, markNotificationsAsRead } from '../../services/api';
import { formatRelativeTime } from '../../utils/dateUtils';

interface Notification {
  id: number;
  type: number;
  from_user: {
    user_id: number;
    username: string;
    display_name: string;
    avatar: string | null;
  };
  review?: {
    id: number;
    game_id: number;
    game_name: string;
  };
  is_read: boolean;
  created_at: string;
}

const NotificationsMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchNotifications = async (pageNum: number = 1) => {
    try {
      setLoading(true);
      const response = await getNotifications(pageNum);
      if (pageNum === 1) {
        setNotifications(response.results);
      } else {
        setNotifications(prev => [...prev, ...response.results]);
      }
      setUnreadCount(response.results.filter((n: Notification) => !n.is_read).length);
      setHasMore(response.next);
    } catch (error) {
      console.error('通知の取得に失敗しました:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (!loading && hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchNotifications(nextPage);
    }
  };

  useEffect(() => {
    fetchNotifications();
    // 1分ごとに通知を更新
    const interval = setInterval(fetchNotifications, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (unreadCount > 0) {
      markNotificationsAsRead().catch(error => {
        console.error('通知の既読処理に失敗しました:', error);
      });
      setUnreadCount(0);
      setNotifications(notifications.map(n => ({ ...n, is_read: true })));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification: Notification) => {
    if (notification.review) {
      navigate(`/game/${notification.review.game_id}/reviews/${notification.review.id}`);
    } else if (notification.type === 2) { // フォロー通知の場合
      navigate(`/profile/${notification.from_user.username}`);
    }
    handleClose();
  };

  const handleUserClick = (event: React.MouseEvent, username: string) => {
    event.stopPropagation();
    navigate(`/profile/${username}`);
    handleClose();
  };

  const getNotificationText = (notification: Notification): string => {
    switch (notification.type) {
      case 1:
        return 'さんがレビューにいいね！しました';
      case 2:
        return 'さんがフォローしました';
      case 3:
        return 'さんがメンションしました';
      default:
        return '';
    }
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="large"
        color="inherit"
        sx={{ mr: 0 }}
      >
        <Badge badgeContent={unreadCount} color="primary">
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: 360,
            maxHeight: 480,
            boxShadow: '0 8px 24px rgba(140,149,159,0.2)',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: '6px',
            mt: 0.5
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ p: 1 }}>
          <List sx={{ p: 0 }}>
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <ListItem
                  key={notification.id}
                  disablePadding
                >
                  <ListItemButton
                    onClick={() => handleNotificationClick(notification)}
                    sx={{
                      py: 0.5,
                      borderRadius: 1,
                      backgroundColor: notification.is_read ? 'inherit' : 'action.hover',
                      '&:hover': { backgroundColor: 'action.hover' }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={notification.from_user.avatar ? `https://media.plsdb.com/avatars/${notification.from_user.user_id}/${notification.from_user.avatar}.jpg` : undefined}
                        alt={notification.from_user.display_name}

                        sx={{ 
                          width: 36, 
                          height: 36,
                          cursor: 'pointer',
                          '&:hover': {
                            opacity: 0.8,
                          }
                        }}
                        onClick={(e) => handleUserClick(e, notification.from_user.username)}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box component="span" sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="body2" sx={{ lineHeight: 1.4 }}>
                            <Box 
                              component="span" 
                              sx={{ 
                                fontWeight: 600,
                                cursor: 'pointer',
                                '&:hover': {
                                  color: 'primary.main',
                                }
                              }}
                              onClick={(e) => handleUserClick(e, notification.from_user.username)}
                            >
                              {notification.from_user.display_name}
                            </Box>
                            {getNotificationText(notification)}
                          </Typography>
                          {notification.review && (
                            <Typography 
                              variant="body2" 
                              color="text.secondary"
                              sx={{ mt: 0.5, fontSize: '0.75rem' }}
                            >
                              {notification.review.game_name}
                            </Typography>
                          )}
                        </Box>
                      }
                      secondary={
                        <Typography 
                          variant="caption" 
                          color="text.secondary"
                          sx={{ mt: 0.5, display: 'block', fontSize: '0.7rem' }}
                        >
                          {formatRelativeTime(notification.created_at)}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))
            ) : (
              <ListItem disablePadding>
                <ListItemButton disabled sx={{ py: 0.5, borderRadius: 1 }}>
                  <ListItemText
                    primary={
                      <Typography 
                        variant="body2" 
                        color="text.secondary" 
                        sx={{ textAlign: 'center' }}
                      >
                        通知はありません
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
            {hasMore && (
              <ListItem>
                <Button
                  onClick={loadMore}
                  disabled={loading}
                  fullWidth
                  size="small"
                >
                  {loading ? '読み込み中...' : 'もっと見る'}
                </Button>
              </ListItem>
            )}
          </List>
        </Box>
      </Menu>
    </>
  );
};

export default NotificationsMenu; 