import React from 'react';
import { Box, Typography, IconButton, Stack, Grid, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { GameListSummary } from '../../services/contents_api';
import { useNavigate } from 'react-router-dom';

interface GameListComponentProps {
  list: GameListSummary;
  onEdit?: (list: GameListSummary) => void;
  onDelete?: (listId: number) => void;
}

const COVER_WIDTH = 120; // 基準となる幅
const COVER_HEIGHT = 160; // 3:4のアスペクト比で計算した高さ
const MAX_COVERS = 4; // 最大表示カバー数

const GameListComponent: React.FC<GameListComponentProps> = ({ list, onEdit, onDelete }) => {
  const navigate = useNavigate();

  const handleTitleClick = () => {
    navigate(`/list/${list.id}`);
  };

  const handleEdit = () => {
    navigate(`/list/${list.id}/edit`);
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={handleTitleClick}
      >
        {/* スタックさ��たカバー画像 */}
        <Box
          className="cover-stack"
          sx={{
            position: 'relative',
            height: COVER_HEIGHT,
            mb: 1.5,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {list.preview_items.slice(0, MAX_COVERS).map((
            item: { id: number; game_cover: string | null }, 
            index: number, 
            array: Array<{ id: number; game_cover: string | null }>
          ) => (
            <Box
              key={item.id}
              sx={{
                position: 'absolute',
                left: `${index * 20}%`,
                width: COVER_WIDTH,
                height: COVER_HEIGHT,
                borderRadius: 1,
                overflow: 'hidden',
                transition: 'transform 0.2s ease',
                zIndex: array.length - index,
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                  zIndex: array.length + 1,
                },
              }}
            >
              <Box
                component="img"
                src={item.game_cover || '/image/no_image.png'}
                alt=""
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  backgroundColor: 'black',
                }}
              />
            </Box>
          ))}
        </Box>

        {/* リスト情報 */}
        <Box>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'flex-start',
            gap: 1,
          }}>
            <Box sx={{ flex: 1, minWidth: 0 }}>
              <Typography 
                component="h3" 
                sx={{ 
                  fontWeight: '600',
                  fontSize: '0.8rem',
                  mb: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {list.title}
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                flexWrap: 'wrap'
              }}>
                {list.list_type === 2 && (
                  <Box
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 0.5,
                      backgroundColor: 'rgba(255, 215, 0, 0.1)',
                      border: '1px solid rgba(255, 215, 0, 0.5)',
                      borderRadius: '4px',
                      padding: '2px 6px',
                      color: '#FFD700',
                    }}
                  >
                    <WorkspacePremiumIcon sx={{ fontSize: '0.7rem' }} />
                    <Typography 
                      component="span" 
                      sx={{ 
                        fontSize: { xs: '0.7rem', sm: '0.7rem' },
                        fontWeight: 400,
                        lineHeight: 1,
                      }}
                    >
                      ranking
                    </Typography>
                  </Box>
                )}
                <Typography 
                  component="span" 
                  color="text.secondary" 
                  sx={{ fontSize: { xs: '0.7rem', sm: '0.7rem' } }}
                >
                  {list.item_count}本のゲーム
                </Typography>
              </Box>
            </Box>
            <Stack 
              direction="row" 
              spacing={0.5} 
              sx={{ flexShrink: 0 }}
            >
              {onEdit && (
                <IconButton 
                  onClick={(e) => { e.stopPropagation(); handleEdit(); }} 
                  size="small"
                  sx={{ 
                    opacity: 0.7,
                    '&:hover': { opacity: 1 }
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
              {onDelete && (
                <IconButton 
                  onClick={(e) => { e.stopPropagation(); onDelete(list.id); }} 
                  size="small" 
                  color="error"
                  sx={{ 
                    opacity: 0.7,
                    '&:hover': { opacity: 1 }
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default GameListComponent; 