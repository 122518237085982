import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box } from '@mui/material';
import { setGameStatus } from '../services/api';
import ConfirmDialog from './dialogs/ConfirmDialog';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import NotStartedOutlinedIcon from '@mui/icons-material/NotStartedOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useNavigate } from 'react-router-dom';
import PopoverMenu from './common/PopoverMenu';

interface GameStatusButtonProps {
  gameId: number;
  initialStatus: number | null;
  onStatusChange: (newStatus: number | null) => void;
}

const GameStatusButton: React.FC<GameStatusButtonProps> = ({
  gameId,
  initialStatus,
  onStatusChange
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [status, setStatus] = useState<number | null>(initialStatus);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    };
    checkLoginStatus();
  }, []);

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = async (newStatus: number | null) => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    if (newStatus === null) {
      setOpenDeleteConfirmDialog(true);
      return;
    }

    try {
      await setGameStatus(gameId, newStatus);
      setStatus(newStatus);
      onStatusChange(newStatus);
    } catch (error: any) {
      console.error('ゲームステータスの更新に失敗しました', error);
      setErrorMessage(error.message);
      setOpenErrorDialog(true);
    }
    handleClose();
  };

  const handleDeleteConfirm = async () => {
    try {
      await setGameStatus(gameId, null);
      setStatus(null);
      onStatusChange(null);
    } catch (error) {
      console.error('ゲームステータスの削除に失敗しました', error);
    }
    setOpenDeleteConfirmDialog(false);
    handleClose();
  };

  const getStatusIcon = (statusCode: number | null) => {
    switch (statusCode) {
      case 1: return <CheckCircleOutlineIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 2: return <PlayCircleOutlineIcon fontSize="small" sx={{ color: 'info.main' }} />;
      case 3: return <PauseCircleOutlineIcon fontSize="small" sx={{ color: 'warning.main' }} />;
      case 4: return <NotStartedOutlinedIcon fontSize="small" sx={{ color: '#b99a71' }} />;
      case 5: return <StarBorderIcon fontSize="small" sx={{ color: 'secondary.main' }} />;
      default: return <AddCircleOutlineIcon fontSize="small" sx={{ color: 'text.secondary' }} />;
    }
  };

  const getStatusText = (statusCode: number | null) => {
    switch (statusCode) {
      case 1: return 'プレイ済';
      case 2: return 'プレイ中';
      case 3: return '中断';
      case 4: return '積みゲー';
      case 5: return '気になる';
      default: return 'ステータスを設定';
    }
  };

  const menuOptions = [
    {
      icon: <CheckCircleOutlineIcon />,
      label: 'プレイ済み',
      onClick: () => handleStatusChange(1),
      iconColor: 'primary.main'
    },
    {
      icon: <PlayCircleOutlineIcon />,
      label: 'プレイ中',
      onClick: () => handleStatusChange(2),
      iconColor: 'info.main'
    },
    {
      icon: <PauseCircleOutlineIcon />,
      label: '中断',
      onClick: () => handleStatusChange(3),
      iconColor: 'warning.main'
    },
    {
      icon: <NotStartedOutlinedIcon />,
      label: '積みゲー',
      onClick: () => handleStatusChange(4),
      iconColor: '#b99a71'
    },
    {
      icon: <StarBorderIcon />,
      label: '気になる',
      onClick: () => handleStatusChange(5),
      iconColor: 'secondary.main'
    },
    ...(status !== null ? [{
      icon: <RemoveCircleOutlineIcon />,
      label: '削除',
      onClick: () => handleStatusChange(null),
      iconColor: 'error.main'
    }] : [])
  ];

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          backdropFilter: 'blur(4px)',
          border: '1px solid rgba(255, 255, 255, 0.08)',
          borderRadius: '12px',
          padding: { xs: '6px 12px', sm: '8px 16px' },
          color: 'text.primary',
          transition: 'all 0.2s ease',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            transform: 'translateY(-1px)',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap:0.75, lineHeight: 1 }}>
          {getStatusIcon(status)}
          
            {getStatusText(status)}

        </Box>
      </Button>

      <PopoverMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        options={menuOptions}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />

      <ConfirmDialog
        open={openDeleteConfirmDialog}
        title="ステータス削除の確認"
        message="ステータスを削除すると、レビューとスコアも削除されます。本当に削除しますか？"
        onConfirm={handleDeleteConfirm}
        onCancel={() => setOpenDeleteConfirmDialog(false)}
        confirmText="削除"
        cancelText="キャンセル"
      />
      <Dialog
        open={openErrorDialog}
        onClose={() => setOpenErrorDialog(false)}
      >
        <DialogTitle>エラー</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorDialog(false)} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GameStatusButton;