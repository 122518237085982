import React, { useState, useEffect, useMemo } from 'react';
import { 
  Container, 
  Typography, 
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  List,
  ListItem,
  IconButton,
  Button,
  Paper,
  Grid,
  Alert
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { useParams, useNavigate } from 'react-router-dom';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { 
  arrayMove, 
  SortableContext, 
  sortableKeyboardCoordinates,
  rectSortingStrategy 
} from '@dnd-kit/sortable';
import { debounce } from 'lodash';
import { 
  GameList, 
  GameListItem, 
  updateGameList, 
  reorderGameListItems, 
  removeGameFromList, 
  searchGames, 
  addGameToList,
  getGameListDetail 
} from '../services/contents_api';
import { SortableGameItem } from '../components/GameList/SortableGameItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CheckIcon from '@mui/icons-material/Check';

interface SearchResult {
  game_id: number;
  name: string;
  japanese_name: string | null;
  first_release_date: string | null;
}

const EditGameListPage: React.FC = () => {
  const { listId } = useParams<{ listId: string }>();
  const navigate = useNavigate();
  const isDevelopment = process.env.NODE_ENV === 'development';
  const MAX_ITEMS = isDevelopment ? 100 : 50;
  const [list, setList] = useState<GameList | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [visibility, setVisibility] = useState(0);
  const [items, setItems] = useState<GameListItem[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Array<{
    game_id: number;
    name: string;
    japanese_name: string | null;
    first_release_date: string | null;
  }>>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedGames, setSelectedGames] = useState<number[]>([]);
  const [searchInLibrary, setSearchInLibrary] = useState(false);
  const [listType, setListType] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [hasMoreResults, setHasMoreResults] = useState(true);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: window.matchMedia('(pointer: fine)').matches ? 0 : 50,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const fetchList = async () => {
      try {
        const data = await getGameListDetail(Number(listId));
        setList(data);
        setTitle(data.title);
        setDescription(data.description);
        setVisibility(data.visibility);
        setListType(data.list_type);
        setItems(data.items);
      } catch (error) {
        setError('リストの読み込みに失敗しました');
        console.error(error);
      }
    };
    if (listId) {
      fetchList();
    }
  }, [listId]);

  const handleSave = async () => {
    try {
      if (!listId) return;
      
      await updateGameList(Number(listId), {
        title,
        description,
        visibility,
        list_type: listType
      });

      if (items.length > 0) {
        await reorderGameListItems(Number(listId), items);
      }

      navigate(`/list/${listId}`);
    } catch (error) {
      setError('リストの更新に失敗しました');
      console.error(error);
    }
  };

  const handleRemoveGame = async (gameId: number) => {
    try {
      if (!listId) return;
      await removeGameFromList(Number(listId), gameId);
      setItems(items.filter(item => item.game_id !== gameId));
    } catch (error) {
      setError('ゲームの削除に失敗しました');
      console.error(error);
    }
  };

  const performSearch = async (query: string, inLibrary: boolean, page: number = 1) => {
    if (query.length < 2) {
      setSearchResults([]);
      return;
    }
    setIsSearching(true);
    try {
      const response = await searchGames(query, inLibrary, page);
      if (page === 1) {
        setSearchResults(response.results);
      } else {
        setSearchResults(prev => [...prev, ...response.results]);
      }
      setHasMoreResults(response.has_next);
    } catch (error) {
      console.error('ゲーム検索に失敗しました:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const debouncedSearch = useMemo(
    () => debounce(performSearch, 300),
    []
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    setSearchPage(1);
    debouncedSearch(query, searchInLibrary, 1);
  };

  const handleGameSelect = (gameId: number) => {
    setSelectedGames(prev => 
      prev.includes(gameId)
        ? prev.filter(id => id !== gameId)
        : [...prev, gameId]
    );
  };

  const handleAddSelectedGames = async () => {
    try {
      if (!listId) return;
      
      if (items.length + selectedGames.length > MAX_ITEMS) {
        setError(`リストに追加できるゲームは最大${MAX_ITEMS}件までです`);
        return;
      }
      
      for (const gameId of selectedGames) {
        await addGameToList(Number(listId), gameId);
      }

      const updatedList = await getGameListDetail(Number(listId));
      setItems(updatedList.items);
      
      setSelectedGames([]);
      setSearchQuery('');
      setSearchResults([]);
    } catch (error) {
      setError('ゲームの追加に失敗しました');
      console.error(error);
    }
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const existingGameIds = useMemo(() => {
    return new Set(items.map(item => item.game_id));
  }, [items]);

  const handleLoadMore = () => {
    const nextPage = searchPage + 1;
    setSearchPage(nextPage);
    performSearch(searchQuery, searchInLibrary, nextPage);
  };

  if (!list) {
    return <Container><Typography>読み込み中...</Typography></Container>;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>リストを編集</Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="タイトル"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              size="small"
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="説明"
              fullWidth
              multiline
              rows={2}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel>リストタイプ</InputLabel>
              <Select
                value={listType}
                label="リストタイプ"
                onChange={(e) => setListType(Number(e.target.value))}
              >
                <MenuItem value={1}>コレクション</MenuItem>
                <MenuItem value={2}>ランキング</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {isDevelopment && (
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel>公開設定</InputLabel>
                <Select
                  value={visibility}
                  label="公開設定"
                  onChange={(e) => setVisibility(Number(e.target.value))}
                >
                  <MenuItem value={1}>公開</MenuItem>
                  <MenuItem value={0}>非公開</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Paper>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">ゲームを追加</Typography>
          <Typography variant="body2" color="text.secondary">
            {items.length}/{MAX_ITEMS}件
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
        )}

        {items.length >= MAX_ITEMS && (
          <Alert severity="info" sx={{ mb: 2 }}>
            リストに追加できるゲームは最大{MAX_ITEMS}件までです。入れ替える場合は追加済みのタイトルを削除してください。
          </Alert>
        )}

        {items.length < MAX_ITEMS && (
          <>
            <Box sx={{ mb: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={searchInLibrary}
                    onChange={(e) => {
                      const newValue = e.target.checked;
                      setSearchInLibrary(newValue);
                      if (searchQuery.length >= 2) {
                        performSearch(searchQuery, newValue);
                      }
                    }}
                    size="small"
                  />
                }
                label="ライブラリ内のみ検索"
              />
            </Box>

            <TextField
              fullWidth
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="ゲーム名を入力して検索..."
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />
              }}
              size="small"
              sx={{ mb: 1 }}
            />
            
            <Typography 
              variant="caption" 
              color="text.secondary"
              sx={{ display: 'block', mb: 1, lineHeight: 1.25 }}
            >
              見つからない場合、英題で検索するとより多くの結果が得られるかもしれません
            </Typography>

            {isSearching && (
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>検索中...</Typography>
            )}

            {searchResults.length > 0 && (
              <>
                <List dense>
                  {searchResults.map((game) => {
                    const isExisting = existingGameIds.has(game.game_id);
                    const isSelected = selectedGames.includes(game.game_id);
                    
                    return (
                      <ListItem
                        key={game.game_id}
                        onClick={() => !isExisting && handleGameSelect(game.game_id)}
                        sx={{
                          cursor: isExisting ? 'default' : 'pointer',
                          '&:hover': { 
                            bgcolor: isExisting ? 'inherit' : 'action.hover' 
                          },
                          borderRadius: 1,
                          py: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            color: isExisting ? 'text.disabled' : 'text.primary',
                            fontSize: '0.875rem'
                          }}
                        >
                          {game.japanese_name || game.name}
                          {game.first_release_date && 
                            ` (${new Date(game.first_release_date).getFullYear()})`
                          }
                          <Box 
                            component="span"
                            sx={{ 
                              ml: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              color: isExisting 
                                ? 'text.disabled'
                                : isSelected 
                                  ? 'primary.main'
                                  : 'transparent'
                            }}
                          >
                            <CheckIcon fontSize="small" />
                          </Box>
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>

                {hasMoreResults && !isSearching && (
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="outlined"
                      onClick={handleLoadMore}
                      size="small"
                    >
                      さらに読み込む
                    </Button>
                  </Box>
                )}

                {selectedGames.length > 0 && (
                  <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      onClick={handleAddSelectedGames}
                      startIcon={<AddIcon />}
                      size="small"
                    >
                      選択したゲームを追加 ({selectedGames.length})
                    </Button>
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Paper>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 0 }}>ゲームリスト</Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            ドラッグ＆ドロップで並び替えができます
        </Typography>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[
            (args) => ({
              ...args,
              x: args.transform.x,
              y: args.transform.y,
              scaleX: 1,
              scaleY: 1,
            })
          ]}
        >
          <SortableContext
            items={items.map(item => item.id)}
            strategy={rectSortingStrategy}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: 'repeat(3, 1fr)',
                  sm: 'repeat(4, 1fr)',
                  md: 'repeat(6, 1fr)'
                },
                gap: 2
              }}
            >
              {items.map((item) => (
                <Box key={item.id} sx={{ position: 'relative' }}>
                  <SortableGameItem item={item} />
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveGame(item.game_id)}
                    sx={{
                      position: 'absolute',
                      top: 2,
                      right: 2,
                      bgcolor: 'rgba(0, 0, 0, 0.4)',
                      '&:hover': {
                        bgcolor: 'error.main',
                        color: 'white'
                      },
                      padding: '4px'
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </SortableContext>
        </DndContext>
      </Paper>

      <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          onClick={() => navigate(`/list/${listId}`)}
          size="small"
        >
          キャンセル
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          size="small"
        >
          保存
        </Button>
      </Box>
    </Container>
  );
};

export default EditGameListPage; 