import { createTheme, PaletteOptions } from '@mui/material/styles';

// PaletteOptionsを拡張してoriginalプロパティを追加
interface ExtendedPaletteOptions extends PaletteOptions {
  pulse: {
    info: string;
  };
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#d9ff00',
    },
    secondary: {
      main: '#92b304',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    pulse: {
      info: '#a1a1a1',
    },
  } as ExtendedPaletteOptions, // as ExtendedPaletteOptionsで型を明示的に指定
  typography: {
    fontSize: 12,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '1.75rem',
      },
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h4: {
      fontSize: '1.4rem',
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '1.1rem',
      },
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
    h6: {
      fontSize: '0.9rem',
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '0.8rem',
      },
    },
    body1: {
      fontSize: '0.9rem',
      '@media (max-width:600px)': {
        fontSize: '0.8rem',
      },
    },
    body2: {
      fontSize: '0.8rem',
      '@media (max-width:600px)': {
        fontSize: '0.7rem',
      },
    },
    caption: {
      fontSize: '0.65rem',
      '@media (max-width:600px)': {
        fontSize: '0.6rem',
      },
    },
    subtitle1: {
      fontSize: '0.75rem',
      '@media (max-width:600px)': {
        fontSize: '0.7rem',
      },
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#1e1e1e',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
        },
      },
    },
  },
});

export default theme;