import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import NotStartedOutlinedIcon from '@mui/icons-material/NotStartedOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';

// ステータスの定義
const STATUS_CONFIG = {
  s1: {
    label: 'プレイ済み',
    icon: CheckCircleOutlineIcon,
    color: 'primary.main'
  },
  s2: {
    label: 'プレイ中',
    icon: PlayCircleOutlineIcon,
    color: 'info.main'
  },
  s3: {
    label: '中断',
    icon: PauseCircleOutlineIcon,
    color: 'warning.main'
  },
  s4: {
    label: '積みゲー',
    icon: NotStartedOutlinedIcon,
    color: '#b99a71'
  },
  s5: {
    label: '気になる',
    icon: StarBorderIcon,
    color: 'secondary.main'
  }
} as const;

interface GameStatusCountProps {
  counts: {
    s1: number;
    s2: number;
    s3: number;
    s4: number;
    s5: number;
  };
  isMobile?: boolean;
}

const StatusContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(1.5),
}));

const TotalCount = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(0.5, 0),
  borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
  marginBottom: theme.spacing(0.5),
}));

const StatusList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

const StatusItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(0.25, 0),
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
  }
}));

const GameStatusCount: React.FC<GameStatusCountProps> = ({ counts, isMobile }) => {
  const totalCount = Object.values(counts).reduce((acc, curr) => acc + curr, 0);

  if (totalCount === 0) return null;

  return (
    <StatusContainer sx={isMobile ? {
      padding: 0,
      paddingRight: 1,
      // backgroundColor: 'rgba(0, 0, 0, 0.4)',
    } : undefined}>
      <TotalCount sx={isMobile ? {
        padding: 0,
        borderBottom: 'none',
        marginBottom: 0,
        justifyContent: 'flex-end'
      } : undefined}>
        <Typography
          variant="body2"
          sx={{
            fontSize: isMobile ? '0.8rem' : '0.9rem',
            lineHeight: '1',
            fontWeight: 'medium',
            color: 'text.primary',
            order: isMobile ? 1 : 2
          }}
        >
          {totalCount}
        </Typography>
        <PeopleAltOutlinedIcon sx={{ 
          fontSize: isMobile ? '0.9rem' : '1.2rem', 
          color: 'text.secondary',
          order: isMobile ? 2 : 1
        }} />
      </TotalCount>
      <StatusList sx={isMobile ? {
        gap: 0.5
      } : undefined}>
        {Object.entries(counts).map(([key, count]) => {
          if (count === 0) return null;
          const config = STATUS_CONFIG[key as keyof typeof STATUS_CONFIG];
          const Icon = config.icon;
          
          return (
            <Tooltip key={key} title={config.label} arrow placement="left">
              <StatusItem sx={isMobile ? {
                padding: 0,
                justifyContent: 'flex-end'
              } : undefined}>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: isMobile ? '0.7rem' : '0.8rem',
                    lineHeight: '1',
                    color: 'text.secondary',
                    fontWeight: 'medium',
                    order: isMobile ? 1 : 2
                  }}
                >
                  {count}
                </Typography>
                <Icon sx={{ 
                  fontSize: isMobile ? '0.8rem' : '0.9rem', 
                  color: config.color,
                  order: isMobile ? 2 : 1
                }} />
              </StatusItem>
            </Tooltip>
          );
        })}
      </StatusList>
    </StatusContainer>
  );
};

export default GameStatusCount;
