import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button, Box, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { getTrendingNewReleases, getAnticipatedGames, getPopular2024Games, getBasicGameStatuses } from '../services/api';
import GameCard from '../components/GameCard';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import { Game } from '../types/Game';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import GroupsIcon from '@mui/icons-material/Groups';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import YearlyGamesSection from '../components/YearlyGamesSection';
import { SectionTitle } from '../components/common/SectionTitle';
import ProjectCTA from '../components/common/ProjectCTA';
import IntroductionSection from '../components/IntroductionSection';
import { isPwa } from '../utils/isPwa';

const HomePage: React.FC = () => {
  const [trendingGames, setTrendingGames] = useState<Game[]>([]);
  const [anticipatedGames, setAnticipatedGames] = useState<Game[]>([]);
  const [popular2024Games, setPopular2024Games] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [gameStatuses, setGameStatuses] = useState<{ [key: number]: number | null }>({});

  const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;

  const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  `;

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);

    // ログイン状態の変更を監視
    const handleStorageChange = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    };
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('loginSuccess', handleStorageChange);
    window.addEventListener('logout', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('loginSuccess', handleStorageChange);
      window.removeEventListener('logout', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      setLoading(true);
      try {
        const [trendingResponse, anticipatedResponse, popular2024Response] = await Promise.all([
          getTrendingNewReleases(),
          getAnticipatedGames(),
          getPopular2024Games(),
        ]);
        setTrendingGames(trendingResponse.data.results);
        setAnticipatedGames(anticipatedResponse.data.results);
        setPopular2024Games(popular2024Response.data.results);
      } catch (error) {
        console.error('データの取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  // ゲームデータ取得後にステータスを一括取得
  useEffect(() => {
    const fetchGameStatuses = async () => {
      if (!isLoggedIn) return;
      
      const allGames = [...trendingGames, ...anticipatedGames, ...popular2024Games];
      if (allGames.length === 0) return;

      const gameIds = allGames.map(game => game.game_id);
      const statuses = await getBasicGameStatuses(gameIds);
      setGameStatuses(statuses);
    };

    fetchGameStatuses();
  }, [trendingGames, anticipatedGames, popular2024Games, isLoggedIn]);

  // ゲームリストをシャッフルする関数
  const shuffleGames = (games: Game[]) => {
    return [...games].sort(() => Math.random() - 0.5);
  };

  // スクロールアニメーションを表示するかどうかの条件
  const shouldShowScrollAnimation = !isLoggedIn && !isPwa();

  if (loading) {
    return (
      <>
        <Box
          sx={{
            background: (theme) => `linear-gradient(145deg, ${theme.palette.background.paper}, rgba(0, 0, 0, 0.2))`,
            minHeight: '85vh',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            mb: 6,
            pt: '64px',
            overflow: 'hidden',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '300px',
              background: `linear-gradient(to bottom, transparent, ${theme.palette.background.default})`,
              pointerEvents: 'none',
              zIndex: 1
            }
          }}
        >
          {/* 背景のグラデーション効果 */}
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.2), transparent 70%)',
            zIndex: 0
          }} />

          {/* カバー画像グリッド */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(4, 1fr)',  // モバイルでは4列
                sm: 'repeat(6, 1fr)',  // タブレットでは6列
                md: 'repeat(9, 1fr)',  // PCでは8列
              },
              gap: 0.5,
              opacity: 0.25,
            }}
          >
            {shuffleGames([...trendingGames, ...anticipatedGames, ...popular2024Games])
              .slice(0, 24)
              .map((game, index) => (
                <Box
                  key={game.game_id}
                  sx={{
                    aspectRatio: '3/4',
                    backgroundImage: `url(${game.cover})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    animation: `${fadeIn} 0.5s ease-out ${index * 0.1}s backwards`,
                  }}
                />
              ))}
          </Box>

          {/* メインコンテンツ */}
          <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
            <Box sx={{ 
              height: '80vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pt: '64px',
            }}>
              {/* ここにローディングスピナーやシンプルなアニメーションを配置 */}
            </Box>
          </Container>
        </Box>

        <IntroductionSection loading={true} />

        <Container maxWidth="lg" sx={{ px: { xs: 1.5, sm: 3 } }}>
          <SectionTitle icon={<WhatshotIcon />} text="話題の新作" />
          <LoadingPlaceholder type="card" count={12} />

          <SectionTitle icon={<NewReleasesIcon />} text="注目の期待作" />
          <LoadingPlaceholder type="card" count={12} />

          <SectionTitle icon={<GroupsIcon />} text="人気のゲーム（2024年）" />
          <LoadingPlaceholder type="card" count={12} />

          <ProjectCTA loading={true} />
          
          <YearlyGamesSection loading={true} />
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>PULSE - ゲーム体験を記録できる究極のゲームライブラリ</title>
        <meta 
          name="description" 
          content="国内最大級のゲームデータベースで、あなたのゲーム体験を記録・管理。プレイ履歴の記録、進行中のゲーム管理、気になるタイトルの保存まで。詳細なステータス管理とレビュー・スコアリングで、あなただけのゲームライブラリを作成できます。"
        />
        <meta 
          property="og:title" 
          content="PULSE - ゲーム体験を記録できる究極のゲームライブラリ" 
        />
        <meta 
          property="og:description" 
          content="国内最大級のゲームデータベースで、あなたのゲーム体験を記録・管理。プレイ履歴の記録、進行中のゲーム管理、気になるタイトルの保存まで。詳細なステータス管理とレビュー・スコアリングで、あなただけのゲームライブラリを作成できます。" 
        />
      </Helmet>

      <Box
        sx={{
          background: (theme) => `linear-gradient(145deg, ${theme.palette.background.paper}, rgba(0, 0, 0, 0.2))`,
          minHeight: isLoggedIn ? '100vh' : '100vh',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          mb: 6,
          pt: '64px',
          overflow: 'hidden',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '300px',
            background: `linear-gradient(to bottom, transparent, ${theme.palette.background.default})`,
            pointerEvents: 'none',
            zIndex: 1
          }
        }}
      >
        {/* 背景のグラデーション効果 */}
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.2), transparent 70%)',
          zIndex: 0
        }} />

        {/* カバー画像グリッド */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(4, 1fr)',  // モバイルでは4列
              sm: 'repeat(6, 1fr)',  // タブレットでは6列
              md: 'repeat(9, 1fr)',  // PCでは8列
            },
            gap: 0.5,
            opacity: 0.2,
          }}
        >
          {shuffleGames([...trendingGames, ...anticipatedGames, ...popular2024Games])
            .slice(0, 36)
            .map((game, index) => (
              <Box
                key={game.game_id}
                sx={{
                  aspectRatio: '3/4',
                  backgroundImage: `url(${game.cover})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  animation: `${fadeIn} 0.5s ease-out ${index * 0.1}s backwards`,
                }}
              />
            ))}
        </Box>

        {/* メインコンテンツ */}
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
          <Box sx={{ 
            maxWidth: '1280px', 
            color: 'white',
            pl: { xs: 0, sm: 2 },
            textAlign: { xs: 'left', sm: 'center' }
          }}>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontWeight: 'bold',
                mt: 3,
                mb: 3,
                fontSize: { xs: '2.4rem', sm: '3.2rem', md: '4rem' },
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textShadow: `0 0 20px ${theme.palette.primary.main}22`,
                '& > span': {
                  display: { xs: 'block', sm: 'inline' }, // スマホのみ改行
                },
                '& > span:last-child': {
                  display: 'block' // 「もっと面白い」は常に改行
                },
                animation: `${fadeIn} 1s ease-out`,
              }}
            >
              <span>ゲームは、</span>
              <span>記録すると</span>
              <span>もっと面白い。</span>
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                opacity: 0.9,
                fontSize: { xs: '0.8rem', sm: '1.3rem', md: '1.5rem' },
                color: 'rgba(255,255,255,0.9)',
                maxWidth: '1000px',
                mx: 'auto',
                lineHeight: 1.6,
                animation: `${fadeIn} 1s ease-out 0.3s backwards`,
              }}
            >
              22万本超の国内最大級データベースで、あなたのゲーム体験を記録・管理し、究極のゲームライブラリをつくろう
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              width: {
                xs: '100%',
                sm: 'auto'
              },
              justifyContent: { xs: 'flex-start', sm: 'center' },
              animation: `${fadeIn} 1s ease-out 0.6s backwards`,
            }}>
              <Button
                component={Link}
                to={isLoggedIn ? "/profile" : "/login"}
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.background.default,
                  fontWeight: 'bold',
                  px: { xs: 2, sm: 4 },
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  }
                }}
              >
                {isLoggedIn ? "ゲームライブラリへ" : "今すぐライブラリを開設"}
              </Button>
              <Button
                component={Link}
                to="/search"
                variant="outlined"
                size="large"
                sx={{
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  backgroundColor: `${theme.palette.primary.main}11`,
                  px: { xs: 2, sm: 4 },
                  '&:hover': {
                    borderColor: theme.palette.primary.light,
                    backgroundColor: `${theme.palette.primary.main}21`
                  }
                }}
              >
                PULSEでゲームを探す
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      {!isLoggedIn && <IntroductionSection loading={loading} />}

      <Container maxWidth="lg" sx={{ px: { xs: 1.5, sm: 3 } }}>
        <SectionTitle icon={<WhatshotIcon />} text="話題の新作" />
        <Grid container spacing={{ xs: 1, sm: 1.5 }}>
          {trendingGames.map((game) => (
            <Grid item xs={4} sm={3} md={2} key={game.game_id}>
              <GameCard 
                game={game} 
                initialStatus={gameStatuses[game.game_id]}
              />
            </Grid>
          ))}
        </Grid>

        <SectionTitle icon={<NewReleasesIcon />} text="注目の期待作" />
        <Grid container spacing={{ xs: 1, sm: 1.5 }}>
          {anticipatedGames.map((game) => (
            <Grid item xs={4} sm={3} md={2} key={game.game_id}>
              <GameCard 
                game={game} 
                initialStatus={gameStatuses[game.game_id]}
              />
            </Grid>
          ))}
        </Grid>

        <SectionTitle icon={<GroupsIcon />} text="人気のゲーム（2024年）" />
        <Grid container spacing={{ xs: 1, sm: 1.5 }}>
          {popular2024Games.map((game) => (
            <Grid item xs={4} sm={3} md={2} key={game.game_id}>
              <GameCard 
                game={game} 
                initialStatus={gameStatuses[game.game_id]}
              />
            </Grid>
          ))}
        </Grid>

        {!isLoggedIn && <ProjectCTA loading={loading} />}
        
        <YearlyGamesSection loading={loading} />
        
      </Container>

      {/* スクロールアニメーションの条件を変更 */}
      {shouldShowScrollAnimation && (
        <Box
          sx={{
            position: 'absolute',
            bottom: { xs: '1rem', sm: '2rem' }, // モバイルでは少し上に
            left: '50%',
            transform: 'translateX(-50%)',
            color: 'white',
            opacity: 0.3,
            cursor: 'pointer',
            animation: `${bounce} 2s infinite`,
            zIndex: 10,
            '&:hover': {
              opacity: 1
            }
          }}
          onClick={() => window.scrollTo({
            top: window.innerHeight - 100, // 高さに100pxのオフセットを追加
            behavior: 'smooth'
          })}
        >
          <Box sx={{ 
            width: { xs: '24px', sm: '40px' }, // モバイルでは少し小さく
            height: { xs: '24px', sm: '40px' },
            borderLeft: '3px solid currentColor',
            borderBottom: '3px solid currentColor',
            transform: 'rotate(-45deg)',
          }} />
        </Box>
      )}
    </>
  );
};

export default HomePage;
