import React from 'react';
import { Box, Skeleton, Grid } from '@mui/material';

interface LoadingPlaceholderProps {
  type: 'card' | 'detail' | 'review' | 'reviewPage';
  count?: number;
}

const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = ({ type, count = 1 }) => {
  if (type === 'card') {
    return (
      <Grid container spacing={{ xs: 1, sm: 1.5 }}>
        {[...Array(count)].map((_, index) => (
          <Grid item xs={4} sm={3} md={2} key={index}>
            <Box sx={{ width: '100%', height: '100%', minHeight: 250 }}>
              <Skeleton 
                variant="rectangular" 
                width="100%" 
                height={0}
                sx={{ paddingTop: '133.33%', borderRadius: 2 }} // 3:4のアスペクト比、角丸
              />
              <Box sx={{ pt: 1.5, px: 1 }}>
                <Skeleton width="100%" height={20} />
                <Skeleton width="40%" height={16} />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  }

  if (type === 'detail') {
    return (
      <Box sx={{ width: '100%' }}>
        <Skeleton variant="rectangular" width="100%" height={450} />
        <Box sx={{ pt: 1.5 }}>
          <Skeleton width="60%" height={40} />
          <Skeleton />
          <Skeleton />
          <Skeleton width="80%" />
        </Box>
      </Box>
    );
  }

  if (type === 'review') {
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', mb: 4 }}>
          <Skeleton variant="rectangular" width={150} height={200} sx={{ borderRadius: 2 }} />
          <Box sx={{ ml: 3, flex: 1 }}>
            <Skeleton width="60%" height={40} />
            <Skeleton width="40%" height={30} />
            <Skeleton width="30%" height={50} sx={{ mt: 2 }} />
          </Box>
        </Box>
        {[...Array(count)].map((_, index) => (
          <Box key={index} sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Skeleton variant="circular" width={40} height={40} />
              <Box sx={{ ml: 2 }}>
                <Skeleton width={120} height={24} />
                <Skeleton width={80} height={16} />
              </Box>
            </Box>
            <Skeleton width="100%" height={20} />
            <Skeleton width="100%" height={20} />
            <Skeleton width="80%" height={20} />
          </Box>
        ))}
      </Box>
    );
  }

  if (type === 'reviewPage') {
    return (
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={2}>
            <Skeleton variant="rectangular" width="100%" height={0} sx={{ paddingTop: '133.33%', borderRadius: 2 }} />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Skeleton width="80%" height={40} />
            <Skeleton width="60%" height={30} sx={{ mt: 1 }} />
            <Skeleton width="40%" height={50} sx={{ mt: 2 }} />
          </Grid>
        </Grid>
        {[...Array(count)].map((_, index) => (
          <Box key={index} sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Skeleton variant="circular" width={40} height={40} />
              <Box sx={{ ml: 2 }}>
                <Skeleton width={120} height={24} />
                <Skeleton width={80} height={16} />
              </Box>
            </Box>
            <Skeleton width="20%" height={30} />
            <Skeleton width="100%" height={20} />
            <Skeleton width="100%" height={20} />
            <Skeleton width="80%" height={20} />
          </Box>
        ))}
      </Box>
    );
  }

  return null;
};

export default LoadingPlaceholder;
