import React, { useState } from 'react';
import { Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Typography } from '@mui/material';
import { UserProfile } from '../../types/UserProfile';

interface PrivacySettingsFormProps {
  profile: UserProfile;
  onSave: (updatedProfile: UserProfile) => Promise<void>;
}

const PrivacySettingsForm: React.FC<PrivacySettingsFormProps> = ({ profile, onSave }) => {
  const [agePrivacy, setAgePrivacy] = useState(profile.age_privacy.toString());

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await onSave({ ...profile, age_privacy: parseInt(agePrivacy) });
    } catch (error) {
      console.error('プライバシー設定の更新に失敗しました', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <FormControl component="fieldset">
        <FormLabel component="legend">生年月日の利用設定</FormLabel>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          ユーザーの生年月日をパブリックで使用するかどうかを選択します。デフォルトでは、"20代後半"のように曖昧に利用されます。完全に非公開とする場合は"使用しない"を選択してください。"使用しない"に設定すると、PULSEの一部サービスが提供されません。
        </Typography>
        <RadioGroup
          aria-label="age-privacy"
          name="age-privacy"
          value={agePrivacy}
          onChange={(e) => setAgePrivacy(e.target.value)}
        >
          <FormControlLabel value="2" control={<Radio />} label="使用（より詳細な表示に活用）" />
          <FormControlLabel value="1" control={<Radio />} label="使用（デフォルト）" />
          <FormControlLabel value="0" control={<Radio />} label="使用しない" />
        </RadioGroup>
      </FormControl>
      <Box mt={2}>
        <Button type="submit" variant="contained" color="primary">
          保存
        </Button>
      </Box>
    </Box>
  );
};

export default PrivacySettingsForm;