// プラットフォームIDと名前のマッピング
export const platformMap: { [key: number]: string } = {
  3: 'Linux',
  4: 'N64', 
  5: 'Wii',
  6: 'PC',
  7: 'PS1',
  8: 'PS2',
  9: 'PS3',
  11: 'XBOX',
  12: 'X360',
  13: 'DOS',
  14: 'Mac',
  15: 'C64',
  16: 'Amiga',
  18: 'NES',
  19: 'SNES',
  20: 'NDS',
  21: 'NGC',
  22: 'GBC',
  23: 'DC',
  24: 'GBA',
  25: 'ACPC',
  26: 'ZXS',
  27: 'MSX',
  29: 'Genesis/MD',
  30: 'Sega32',
  32: 'Saturn',
  33: 'GB',
  34: 'Android',
  35: 'Game Gear',
  37: '3DS',
  38: 'PSP',
  39: 'iOS',
  41: 'WiiU',
  42: 'NGage',
  46: 'PSV',
  47: 'VC',
  48: 'PS4',
  49: 'XONE',
  50: '3DO',
  51: 'fds',
  52: 'Arcade',
  53: 'MSX2',
  55: 'Mobile',
  57: 'WS',
  58: 'SFC',
  59: 'Atari2600',
  60: 'Atari7800',
  61: 'Lynx',
  62: 'Jaguar',
  63: 'Atari-ST',
  64: 'SMS',
  65: 'Atari8bit',
  66: 'Atari5200',
  67: 'intellivision',
  68: 'colecovision',
  69: 'bbcmicro',
  70: 'vectrex',
  71: 'vic-20',
  72: 'Ouya',
  73: 'blackberry',
  74: 'Win Phone',
  75: 'Apple II',
  77: 'x1',
  78: 'segacd',
  79: 'neogeomvs',
  80: 'neogeoaes',
  82: 'browser',
  84: 'sg1000',
  85: 'donner30',
  86: 'turbografx16',
  87: 'virtualboy',
  90: 'cpet',
  91: 'astrocade',
  93: 'C16',
  95: 'pdp1',
  96: 'pdp10',
  97: 'pdp-8',
  98: 'gt40',
  99: 'FC',
  100: 'analogueelectronics',
  101: 'nimrod',
  102: 'edsac',
  103: 'pdp-7',
  104: 'hp2100',
  105: 'hp3000',
  106: 'sdssigma7',
  107: 'call-a-computer',
  108: 'pdp11',
  109: 'cdccyber70',
  110: 'plato',
  111: 'imlac-pds1',
  112: 'microcomputer',
  123: 'WSC',
  128: 'supergrafx',
  130: 'Switch',
  137: 'New 3DS',
  159: 'DSi',
  161: 'Windows MR',
  162: 'Oculus VR',
  163: 'Steam VR',
  164: 'Daydream',
  165: 'PSVR',
  167: 'PS5',
  169: 'X|S',
  170: 'Stadia',
  384: 'Oculus Quest',
  385: 'Oculus Rift',
  386: 'MQ2',
  387: 'Oculus Go',
  390: 'PSVR2',
  471: 'MQ3'
};

// プラットフォームIDから表示名を取得する関数
export const getPlatformName = (platformId: number): string => {
  return platformMap[platformId] || `other`;
};

// プラットフォームIDの配列から表示用の文字列を生成する関数
export const getPlatformDisplayText = (platformIds: number[] | null): string => {
  if (!platformIds || platformIds.length === 0) return '';
  return platformIds.map(id => getPlatformName(id)).join(', ');
};