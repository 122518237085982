import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Rating,
  Link,
  Chip,
  Grid,
  Paper,
} from "@mui/material";
import { ReviewDetail } from "../types/Review";
import { getReviewsByUser } from "../services/api";
import LoadingPlaceholder from "./LoadingPlaceholder";
import Pagination from "@mui/material/Pagination";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AdvertisementResponsive from "../components/advertisement/Ad_GA_res";

interface ReviewsTabProps {
  username: string;
  isPublic?: boolean;
}

const ReviewsTab: React.FC<ReviewsTabProps> = ({ username, isPublic }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [reviews, setReviews] = useState<ReviewDetail[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [expanded, setExpanded] = useState<number[]>([]);
  const maxLength = {
    xs: 120, // スマートフォン向け
    sm: 220  // タブレット/デスクトップ向け
  };
  const maxLines = 6;

  const scoreTags = [
    { id: 1, label: "趣味趣向補正" },
    { id: 2, label: "思い出補正" },
    { id: 3, label: "IP・シリーズ補正" },
    { id: 4, label: "デベロッパー補正" },
    { id: 5, label: "ソフトウェアバージョン補正" },
    { id: 6, label: "マルチプレイ補正" },
    { id: 7, label: "エントリー補正" },
    { id: 8, label: "コンテキスト補正" },
    { id: 9, label: "下方バイアス" },
    { id: 0, label: "その他の上方補正要因" },
  ];

  // 初期ページ番号をURLから取得
  const initialPage = parseInt(searchParams.get('page') || '1');
  const [page, setPage] = useState(initialPage);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setLoading(true);
        const response = await getReviewsByUser(username, page);
        setReviews(response.results);
        setTotalPages(Math.ceil(response.count / 10));
      } catch (error) {
        console.error("レビューの取得に失敗しました:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [username, page]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    // 現在のURLパラメータを維持しながら、pageパラメータを更新
    setSearchParams(params => {
      params.set('page', value.toString());
      return params;
    });
  };

  const toggleExpand = (reviewId: number) => {
    setExpanded((prev) => [...prev, reviewId]);
  };

  const formatText = (text: string) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split("\n").length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const truncateText = (text: string) => {
    const lines = text.split("\n");
    const isMobile = window.innerWidth < 600; // MUIのブレークポイントに合わせる
    const currentMaxLength = isMobile ? maxLength.xs : maxLength.sm;

    if (lines.length > maxLines || text.length > currentMaxLength) {
      const truncatedLines = lines.slice(0, maxLines);
      let truncatedText = truncatedLines.join("\n");
      if (truncatedText.length > currentMaxLength) {
        truncatedText = truncatedText.slice(0, currentMaxLength);
      }
      return `${truncatedText}...`;
    }
    return text;
  };

  const getCoverUrl = (game: any) => {
    const coverId = game.cover_id;
    return coverId
      ? `https://images.igdb.com/igdb/image/upload/t_cover_big/${coverId}.jpg`
      : "/image/no_image.png"; // フォールバック用の画像パス
  };

  const formatPlayDate = (date: string | null) => {
    if (date === null) return "";
    const playDate = new Date(date);
    return playDate.toLocaleDateString();
  };

  // スコアに応じて色を取得する関数を追加
  const getScoreColor = (score: number | null) => {
    if (score === null) return "#666666"; // スコアがない場合は灰色を返す
    if (score >= 4.5) return "#4CAF50";
    if (score >= 4.0) return "#8BC34A";
    if (score >= 3.5) return "#CDDC39";
    if (score >= 3.0) return "#FFEB3B";
    if (score >= 2.5) return "#FFC107";
    if (score >= 2.1) return "#FF9800";
    return "#F44336";
  };

  // ReviewContentコンポーネントを追加
  const ReviewContent: React.FC<{
    review: ReviewDetail;
    isExpanded: boolean;
    onExpand: (reviewId: number) => void;
  }> = ({ review, isExpanded, onExpand }) => {
    const displayText = isExpanded
      ? review.review_text
      : truncateText(review.review_text);
    
    const isMobile = window.innerWidth < 600;
    const currentMaxLength = isMobile ? maxLength.xs : maxLength.sm;
    const shouldShowReadMore = !isExpanded && (
      review.review_text.length > currentMaxLength ||
      review.review_text.split('\n').length > maxLines
    );

    return (
      <Box>
        <Box sx={{ position: "relative", mt: 1, mb: 0 }}>
          <Typography variant="body2" component="div" color="text.secondary" sx={{ lineHeight: { xs: 1.35, sm: 1.4 } }}>
            {formatText(displayText)}
          </Typography>
          {shouldShowReadMore && (
            <Link
              component="button"
              onClick={() => onExpand(review.id)}
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                cursor: "pointer",
                bgcolor: "background.default",
                pl: 1,
                fontSize: "0.7rem",
              }}
            >
              全て読む
            </Link>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="caption" color="pulse.info" sx={{ mt: 1 }}>
            {new Date(review.created_at).toLocaleDateString()}に投稿
          </Typography>
          <Link
            component={RouterLink}
            to={`/game/${review.game.id}/reviews/${review.id}`}
            sx={{
              display: "flex",
              alignItems: "center",
              color: "pulse.info",
              textDecoration: "none",
              fontSize: "0.75rem",
              "&:hover": {
                color: "primary.main",
              },
            }}
          >
            <OpenInNewIcon sx={{ fontSize: "1rem", ml: 0.5 }} />
          </Link>
        </Box>
      </Box>
    );
  };

  if (loading) {
    return <LoadingPlaceholder type="review" count={10} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8.5}>
        <Box>
          {reviews.length === 0 ? (
            <Paper variant="outlined" sx={{ borderRadius: 1, overflow: 'hidden' }}>
              <Box sx={{ p: 4, textAlign: "center" }}>
                <Typography variant="body1" color="text.secondary">
                  まだレビューがありません
                </Typography>
                {!isPublic && (
                  <Typography variant="body1" color="text.secondary">
                    ゲームをプレイしたら、レビューを書いてみましょう
                  </Typography>
                )}
              </Box>
            </Paper>
          ) : (
            <>
              {reviews.map((review) => {
                const isExpanded = expanded.includes(review.id);
                const displayText = isExpanded
                  ? review.review_text
                  : truncateText(review.review_text);

                return (
                  <Box
                    key={review.id}
                    sx={{
                      mb: 2,
                      borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
                      "&:hover": {
                        transform: "none",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        paddingBottom: { xs: 1, sm: 1.5 },
                        paddingTop: { xs: 1, sm: 1.5 },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <Box
                          component={RouterLink}
                          to={`/game/${review.game.id}`}
                          sx={{
                            display: 'block',
                            width: "20%",
                            maxWidth: 120,
                            mr: 2,
                          }}
                        >
                          <Box
                            component="img"
                            src={getCoverUrl(review.game)}
                            alt={review.game.name}
                            sx={{
                              width: "100%",
                              borderRadius: 1,
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Link
                              component={RouterLink}
                              to={`/game/${review.game.id}`}
                              color="inherit"
                              underline="hover"
                              sx={{
                                typography: "h6",
                                display: "block",
                                mb: 0.5,
                                fontSize: {
                                  xs: "0.75rem!important",
                                  sm: "0.9rem!important",
                                },
                              }}
                            >
                              {review.game.name}
                            </Link>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              flexWrap: "wrap",
                              gap: 1,
                              mb: 1,
                              flexDirection: { xs: "column", sm: "row" },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: { xs: "0.7rem", sm: "0.8rem" },
                                    py: 0.25,
                                    px: 0.75,
                                    lineHeight: "1",
                                    borderRadius: 1,
                                    backgroundColor: `${getScoreColor(
                                      review.score
                                    )}B3`,
                                  }}
                                >
                                  {review.score !== null
                                    ? review.score.toFixed(1)
                                    : "-"}
                                </Typography>
                                <Box
                                  sx={{
                                    width: 80,
                                    height: 4,
                                    bgcolor: "rgba(255, 255, 255, 0.3)",
                                    borderRadius: 2,
                                    overflow: "hidden",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width:
                                        review.score !== null
                                          ? `${((review.score - 1) / 4) * 100}%`
                                          : "100%",
                                      height: "100%",
                                      bgcolor: getScoreColor(review.score),
                                      borderRadius: 2,
                                      transition: "width 0.3s ease",
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              {review.play_time && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    color: "pulse.info",
                                  }}
                                >
                                  <TimerOutlinedIcon
                                    fontSize="inherit"
                                    sx={{ mr: 0.5, fontSize: "0.85rem" }}
                                  />
                                  {review.play_time}時間
                                </Typography>
                              )}
                              {!review.play_time && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    color: "pulse.info",
                                  }}
                                >
                                  <TimerOutlinedIcon
                                    fontSize="inherit"
                                    sx={{ mr: 0.5, fontSize: "0.85rem" }}
                                  />
                                  -
                                </Typography>
                              )}
                              {review.play_start_date && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    color: "pulse.info",
                                  }}
                                >
                                  <PlayCircleOutlineIcon
                                    fontSize="inherit"
                                    sx={{ mr: 0.5, fontSize: "0.85rem" }}
                                  />
                                  {formatPlayDate(review.play_start_date)}
                                  {review.play_end_date && " - "}
                                  {formatPlayDate(review.play_end_date)}
                                </Typography>
                              )}
                              {!review.play_start_date && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    color: "pulse.info",
                                  }}
                                >
                                  <PlayCircleOutlineIcon
                                    fontSize="inherit"
                                    sx={{ mr: 0.5, fontSize: "0.85rem" }}
                                  />
                                  -
                                </Typography>
                              )}
                            </Box>
                          </Box>
                          {review.score_tags &&
                            review.score_tags.map((tagId) => {
                              const tag = scoreTags.find((t) => t.id === tagId);
                              return (
                                tag && (
                                  <Chip
                                    key={tag.id}
                                    icon={
                                      tag.id === 9 ? (
                                        <KeyboardDoubleArrowDownIcon
                                          style={{ color: "#79bcf3" }}
                                        />
                                      ) : (
                                        <KeyboardDoubleArrowUpIcon
                                          style={{ color: "#f37979" }}
                                        />
                                      )
                                    }
                                    label={tag.label}
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                      mr: 0.5,
                                      mb: 0.5,
                                      height: "1.1rem",
                                      "& .MuiChip-icon": { fontSize: "0.7rem" },
                                      "& .MuiChip-label": {
                                        fontSize: "0.6rem",
                                        py: 0.25,
                                      },
                                    }}
                                  />
                                )
                              );
                            })}
                          <ReviewContent
                            review={review}
                            isExpanded={isExpanded}
                            onExpand={toggleExpand}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              {totalPages > 1 && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={3.5} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Box sx={{ position: 'sticky', top: 24 }}>
          <AdvertisementResponsive />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReviewsTab;
