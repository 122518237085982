import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  //<React.StrictMode>
    <App />
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Service Workerの登録
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    // 古いPWAユーザーを検出して再インストールを促す
    // if (window.matchMedia('(display-mode: standalone)').matches) {
    //   navigator.serviceWorker.getRegistrations().then(registrations => {
    //     // Service Workerの登録がない = 古いバージョンのPWA
    //     if (registrations.length === 0) {
    //       alert('アプリケーションが正式版に移行しました。お手数ですが、一度アプリをアンインストールして、再度インストールしてください。');
    //     }
    //   });
    // }
    //ここまで

    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        // 更新確認
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          if (newWorker) {
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                // 更新があることをユーザーに通知
                if (window.confirm('新しいバージョンが利用可能です。更新しますか？')) {
                  // 既存のService Workerを登録解除
                  registration.unregister().then(() => {
                    // キャッシュを全て削除
                    caches.keys().then(cacheNames => {
                      return Promise.all(
                        cacheNames.map(cacheName => caches.delete(cacheName))
                      );
                    }).then(() => {
                      // キャッシュ削除後にページをリロード
                      window.location.reload(); // 強制リロード
                    });
                  });
                }
              }
            });
          }
        });

        // 定期的な更新チェック（オプション）
        setInterval(() => {
          registration.update();
        }, 1000 * 60 * 60 * 1); // 1時間ごと
      })
      .catch(error => {
        console.error('Service Worker の登録に失敗:', error);
      });
  });
}
