import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  Typography, 
  Grid, 
  Box, 
  useTheme,
  useMediaQuery,
  Skeleton,
  IconButton,
  Tooltip,
  Avatar
} from '@mui/material';
import { GameList, getUserGameLists, getGameListDetail } from '../services/contents_api';
import LightweightGameCard from '../components/LightweightGameCard';
import { Helmet } from 'react-helmet-async';
import Advertisement from '../components/advertisement/Ad_wide_728';
import RectangleAdvertisement from '../components/advertisement/Ad_rectangle_300';
import AdvertisementResponsive from '../components/advertisement/Ad_GA_res';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Expandable from '../components/common/Expandable';
import ShareButtons from '../components/common/ShareButtons';
import ListThumbnailGenerator from '../components/GameList/ListThumbnailGenerator';
import ListShareMenu from '../components/GameList/ListShareMenu';

const STORAGE_KEY = 'gamelist-show-titles';
const EXPIRY_KEY = 'gamelist-show-titles-expiry';
const EXPIRY_HOURS = 1;

const GameListDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [list, setList] = useState<GameList | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showTitles, setShowTitles] = useState(() => {
    const saved = sessionStorage.getItem(STORAGE_KEY);
    const expiry = sessionStorage.getItem(EXPIRY_KEY);
    
    if (saved && expiry) {
      if (new Date().getTime() < parseInt(expiry)) {
        return JSON.parse(saved);
      }
    }
    return true;  // デフォルト値
  });
  const [showThumbnailGenerator, setShowThumbnailGenerator] = useState(false);

  useEffect(() => {
    const expiryTime = new Date().getTime() + (EXPIRY_HOURS * 60 * 60 * 1000);
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(showTitles));
    sessionStorage.setItem(EXPIRY_KEY, expiryTime.toString());
  }, [showTitles]);

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      try {
        const listData = await getGameListDetail(Number(id));
        setList(listData);
      } catch (error) {
        console.error('リストの取得に失敗しました:', error);
        navigate('/404');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchList();
    }
  }, [id, navigate]);

  const getRankingStyle = (index: number) => {
    const baseStyle = {
      position: 'absolute',
      top: 1.5,
      right: 1.5,
      zIndex: 1,
      borderRadius: 2,
      width: index < 3 ? 32 : 28,
      height: index < 3 ? 32 : 28,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bold',
      backdropFilter: 'blur(4px)',
      animation: 'shine 2s infinite',
    };

    switch (index) {
      case 0:
        return {
          ...baseStyle,
          background: 'linear-gradient(45deg, rgba(255,215,0,0.8), rgba(255,215,0,0.6))',         
          border: '1px solid rgba(255,215,0,0.3)',
        };
      case 1:
        return {
          ...baseStyle,
          background: 'linear-gradient(45deg, rgba(192,192,192,0.8), rgba(192,192,192,0.6))',         
          border: '1px solid rgba(192,192,192,0.3)',
        };
      case 2:
        return {
          ...baseStyle,
          background: 'linear-gradient(45deg, rgba(205,127,50,0.8), rgba(205,127,50,0.6))',         
          border: '1px solid rgba(205,127,50,0.3)',
        };
      default:
        return {
          ...baseStyle,
          background: 'linear-gradient(45deg, rgba(0,0,0,0.5), rgba(0,0,0,0.3))',
          border: '1px solid rgba(255,255,255,0.1)',
        };
    }
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes shine {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  if (loading) {
    return (
      <Box sx={{ mt: 2 }}>
        <Skeleton variant="text" width="60%" height={40} />
        <Skeleton variant="text" width="40%" height={24} />
        <Skeleton variant="text" width="40%" height={24} sx={{ marginBottom: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={1}>

              {[...Array(50)].map((_, index) => (
                <Grid item xs={4} sm={2.4} md={2.4} key={index}>
                  <Box sx={{ position: 'relative' }}>
                    <Skeleton 
                      variant="rectangular" 
                      sx={{
                        height: 0,
                        paddingTop: '133.33%',
                        borderRadius: '8px'
                      }}
                    />
                    <Skeleton 
                      variant="text" 
                      width="100%" 
                      sx={{ 
                        height: 20,
                        mt: 1
                      }} 
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton 
              variant="rectangular" 
              height={300}
              sx={{ borderRadius: 1 }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (!list) return null;

  return (
    <>
      <Helmet>
        <title>{`${list.title} | PULSE データベース`}</title>
      </Helmet>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" component="h1" gutterBottom>
          {list.title}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Avatar
            src={list.user_avatar || undefined}
            sx={{ 
              width: 24, 
              height: 24, 
              mr: 1,
              fontSize: '0.8rem'
            }}
          >
            {list.display_name[0].toUpperCase()}
          </Avatar>
          <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
            <Link to={`/profile/${list.user_name}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              {list.user_name}
            </Link>
          </Typography>
        </Box>
        {list.description && (
          <Expandable 
            maxLines={3} 
            expandButtonStyle="icon" 
            iconSize={18} 
            iconPadding={4} 
            iconColor="#ffffff" 
            iconBgColor="rgba(0, 0, 0, 0.9)"
          >
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'text.secondary', 
                whiteSpace: 'pre-wrap', 
                mb: 1,
                fontSize: '0.8rem',
                lineHeight: 1.4
              }}
            >
              {list.description}
            </Typography>
          </Expandable>
        )}

        <Box 
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: 'text.secondary',
              whiteSpace: 'nowrap',
              opacity: 0.7,
              fontWeight: 'normal',
              lineHeight: 1
            }}
          >
            {list.items.length}本のゲーム
          </Typography>
          <Box 
            onClick={() => setShowTitles(!showTitles)}
            sx={{ 
              display: 'inline-flex',
              alignItems: 'center', 
              gap: 0.5,
              cursor: 'pointer',
              bgcolor: theme => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.04)',
              borderRadius: 1,
              px: 1,
              py: 0.5,
              userSelect: 'none',
              transition: 'all 0.2s',
              '&:hover': {
                bgcolor: theme => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.08)',
              }
            }}
          >
            {showTitles ? 
              <VisibilityIcon sx={{ fontSize: '0.7rem', color: 'text.secondary' }} /> : 
              <VisibilityOffIcon sx={{ fontSize: '0.7rem', color: '#b64e4e' }} />
            }
            <Typography 
              variant="caption" 
              sx={{ 
                color: 'text.secondary',
                fontSize: '0.6rem',
                lineHeight: 1
              }}
            >
              タイトル{showTitles ? '表示' : '非表示'}
            </Typography>
          </Box>
          <ListShareMenu 
            text={`${list.title} by ${list.display_name} | PULSE - ゲーム記録管理プラットフォーム`}
            url={`/list/${id}`}
            hashtags={['PULSE_DB']}
            buttonText="共有する"
            iconSize={12}
            fontSize={12}
            onThumbnailClick={() => setShowThumbnailGenerator(true)}
          />
        </Box>
        {showThumbnailGenerator && list && list.items.length > 0 && (
          <ListThumbnailGenerator
            items={list.items}
            listTitle={list.title}
            userName={list.user_name}
          />
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={1}>
            {list.items.map((item, index) => (
              <React.Fragment key={item.id}>
                <Grid item xs={4} sm={2.4} md={2.4}>
                  <Box sx={{ 
                    position: 'relative',
                    '& .MuiCardMedia-root': list.list_type === 2 && index < 3 ? {
                      border: theme => `2px solid ${
                        index === 0 ? 'rgba(255,215,0,0.8)' :
                        index === 1 ? 'rgba(192,192,192,0.8)' :
                        'rgba(205,127,50,0.8)'
                      }`,
                    } : {}
                  }}>
                    {list.list_type === 2 && (
                      <Box 
                        className="ranking-badge"
                        sx={{
                          ...getRankingStyle(index),
                          transition: 'transform 0.2s ease-in-out',
                        }}
                      >
                        {index < 3 ? (
                          <WorkspacePremiumIcon 
                            sx={{ 
                              color: 'white',
                              filter: 'drop-shadow(0 0 2px rgba(0,0,0,0.3))'
                            }} 
                          />
                        ) : (
                          <Typography 
                            sx={{ 
                              color: 'white',
                              textShadow: '0 0 2px rgba(0,0,0,0.3)'
                            }}
                          >
                            {index + 1}
                          </Typography>
                        )}
                      </Box>
                    )}
                    <LightweightGameCard
                      game={{
                        game_id: item.game_id,
                        name: item.game_name,
                        japanese_name: item.game_japanese_name,
                        cover: item.game_cover
                      }}
                      hideTitle={!showTitles}
                    />
                    {item.note && (
                      <Typography 
                        variant="caption" 
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          right: 0,
                          bgcolor: 'rgba(0,0,0,0.7)',
                          color: 'white',
                          p: 0.5,
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {item.note}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                {/* {isMobile && index === 11 && (
                  <Grid item xs={12}>
                    <AdvertisementResponsive />
                  </Grid>
                )} */}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          {isMobile ? <RectangleAdvertisement /> : <AdvertisementResponsive />}
        </Grid>
      </Grid>
    </>
  );
};

export default GameListDetailPage;