import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Box, Pagination, Grid, IconButton, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getGameReviews, getGameDetails, getUserGameStatus } from '../services/api';
import ReviewList from '../components/review/ReviewList';
import { Review } from '../types/Review';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import { Game } from '../types/Game';
import GameStatusButton from '../components/GameStatusButton';
import { Helmet } from 'react-helmet-async';
import { SITE_FULL_NAME } from '../constants/site';
import Advertisement336 from '../components/advertisement/Ad_GA_336';

const REVIEWS_PER_PAGE = 10;

const GameReviewsPage: React.FC = () => {
  const { gameId } = useParams<{ gameId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [reviews, setReviews] = useState<Review[]>([]);
  const [game, setGame] = useState<Game | null>(null);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [userGameStatus, setUserGameStatus] = useState<any>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();

  const page = parseInt(searchParams.get('page') || '1', 10);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!gameId) return;
      setLoading(true);
      try {
        const [reviewsData, gameData] = await Promise.all([
          getGameReviews(parseInt(gameId, 10), page),
          getGameDetails(parseInt(gameId, 10))
        ]);

        if (isMounted) {
          setReviews(reviewsData.results);
          setGame(gameData);
          setTotalPages(Math.ceil(reviewsData.count / REVIEWS_PER_PAGE));
        }
      } catch (error) {
        console.error('データの取得に失敗しました', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [gameId, page]);

  useEffect(() => {
    if (!gameId || !isAuthenticated) return;

    const fetchUserStatus = async () => {
      try {
        const statusData = await getUserGameStatus(parseInt(gameId, 10));
        setUserGameStatus(statusData);
      } catch (error) {
        console.error('ユーザーステータスの取得に失敗しました', error);
      }
    };

    fetchUserStatus();
  }, [gameId, isAuthenticated]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setSearchParams({ page: value.toString() });
    window.scrollTo(0, 0);
  };

  const handleReviewClick = () => {
    if (!isAuthenticated) {
      navigate('/login', { state: { from: `/game/${gameId}/reviews` } });
    }
  };

  if (loading) {
    return <LoadingPlaceholder type="reviewPage" count={15} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {game 
            ? `ユーザーレビュー: ${game.japanese_name || game.name} の感想・評価 | ${SITE_FULL_NAME}`
            : `ユーザーレビュー | ${SITE_FULL_NAME}`}
        </title>
      </Helmet>

      <Box sx={{ mt: 2, mb: 4 }}>
        <IconButton
          component={Link}
          to={`/game/${gameId}`}
          sx={{ position: 'absolute', top: 16, left: 16 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={3} sm={2}>
            <Box
              component={Link}
              to={`/game/${gameId}`}
              sx={{
                display: 'block',
                textDecoration: 'none',
                '& img': {
                  width: '100%',
                  height: 'auto',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  transition: 'opacity 0.2s',
                  '&:hover': {
                    opacity: 0.8
                  }
                }
              }}
            >
              <img
                src={game?.cover_hd || '/image/no_image.png'}
                alt={game?.name}
              />
            </Box>
          </Grid>
          <Grid item xs={9} sm={10}>
            <Box
              component={Link}
              to={`/game/${gameId}`}
              sx={{
                textDecorationThickness: '2px',
                color: 'inherit',
                display: 'block',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <Typography 
                variant="h6" 
                component="h2" 
                gutterBottom
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8
                  }
                }}
              >
                {game?.japanese_name || game?.name}
              </Typography>
            </Box>
            <Typography variant="h5" component="h1" gutterBottom>
              ユーザーレビュー
            </Typography>
            <Box sx={{ mt: 'auto' }}>
              {isAuthenticated ? (
                <GameStatusButton
                  gameId={parseInt(gameId!, 10)}
                  initialStatus={userGameStatus?.status}
                  onStatusChange={() => {}}
                />
              ) : (
                <Button variant="contained" onClick={handleReviewClick}>
                  レビューを書く
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <ReviewList reviews={reviews} />
            {totalPages > 1 && (
              <>
                <Box sx={{ mt: 2 }}>
                  <Advertisement336 />
                </Box>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default GameReviewsPage;