import React from 'react';
import { Typography, Box } from '@mui/material';

interface OnlineStatusProps {
  lastAccess: string;
}

const OnlineStatus: React.FC<OnlineStatusProps> = ({ lastAccess }) => {
  const getOnlineStatus = (lastAccessDate: Date) => {
    const now = new Date();
    const diffInMinutes = (now.getTime() - lastAccessDate.getTime()) / (1000 * 60);
    const rtf = new Intl.RelativeTimeFormat('ja', { numeric: 'auto' });

    if (diffInMinutes <= 5) {
      return 'online';
    }

    // 1ヶ月以上前
    // if (diffInMinutes > 43200) { // 30日 * 24時間 * 60分
    //   return '1ヶ月以上前';
    // }
    
    // 1週間以上前
    if (diffInMinutes > 10080) { // 7日 * 24時間 * 60分
      return '1週間以上前';
    }

    // 1日以上前
    if (diffInMinutes >= 1440) {
      const days = Math.floor(diffInMinutes / 1440);
      return rtf.format(-days, 'day');
    }

    // 1時間以上前
    if (diffInMinutes >= 60) {
      const hours = Math.floor(diffInMinutes / 60);
      return rtf.format(-hours, 'hour');
    }

    // 分単位
    return rtf.format(-Math.floor(diffInMinutes), 'minute');
  };

  const status = getOnlineStatus(new Date(lastAccess));
  const isOnline = status === 'online';

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 0.5,
        backgroundColor: isOnline ? 'rgba(31, 136, 61, 0.1)' : 'rgba(140, 140, 140, 0.1)',
        border: 1,
        borderColor: isOnline ? 'rgba(31, 136, 61, 0.3)' : 'rgba(140, 140, 140, 0.3)',
        borderRadius: '12px',
        px: 1,
        py: 0.25,
      }}
    >
      <Box
        sx={{
          width: 6,
          height: 6,
          borderRadius: '50%',
          backgroundColor: isOnline ? '#1ea043' : '#8c8c8c',
        }}
      />
      <Typography 
        variant="body2" 
        sx={{ 
          fontSize: '0.7rem',
          color: isOnline ? '#1ea043' : '#8c8c8c',
          fontWeight: 500,
          lineHeight: 1,
        }}
      >
        {status}
      </Typography>
    </Box>
  );
};

export default OnlineStatus;